<script setup>
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { useValidationStore } from '~/stores/account/validations.store';

const props = defineProps({
	disabled: { type: Boolean, default: false },
	placeholder: { type: String, default: '' },
	readonly: { type: Boolean, default: false },
	isPrice: { type: Boolean, default: false },
	value: [Object, String, Number],
	input: { type: Function, default: () => {} },
	multiLanguage: Boolean,
	name: { type: [String, Number], required: true },
	type: String,
	change: { type: Function, default: () => {} },
	backgroundColor: { type: String, default: 'bg-gray-50' },
	border: { type: String, default: 'rounded' },
	ring: { type: String, default: 'focus:ring-blue-500' },
	showButton: { type: Boolean, default: false },
	required: Boolean,
	options: { type: Array, default: () => [] },
	selectMultiple: { type: Boolean, default: false },
	isThereShowed: { type: Boolean, default: true },
	select: { type: Boolean, default: false },
	searchable: { type: Boolean, default: true },
	trackBy: String,
	label: String,
	defaultOption: String,
	icon: {type: Boolean, default: false},
	isPercentage: { type: Boolean, default: false },
	customSearchBy: { type: Array, default: () => []},
	class: {type: String, default: ''}
});

const emit = defineEmits(['update:value', 'update:item', 'input', 'keyPress']);

const isSelectMenuOpen = ref(false)
let i18n = useI18n();
const fallback_locales = i18n.fallbackLocale.value;
const showed_language = ref(i18n.locale.value);
const showedLanguageBar = ref(false);
const isThereShowedValue = computed(() => showedValue.value != '' && showedValue.value !== undefined);
const selectLabel = props?.label;
const trackBy = props?.trackBy || 'id';
const optionsComputed = computed(() => [
	{
		[props.trackBy]: null,
		[selectLabel]: props.defaultOption || 'Remove Selected'
	},
	...props.options
]);
const isSelectBox = ref(props.selectMultiple || props.select);
const asTextArea = ref(props.type == 'textarea' ? 'textarea' : 'input');
const asType = ref(isSelectBox.value ? 'select-multiple' : asTextArea.value);

const validationStore = useValidationStore();
const validationError = computed(() => validationStore.validations);

const inputEditor = ref(null);
const nameComputed = computed(() => props.name?.toString());
const blankValue = computed(() => (props.selectMultiple ? [] : props.multiLanguage ? {} : ''));
const valueComputed = computed(() => props.value ?? blankValue.value);
const readonlyComputed = computed(() => (props.readonly == true ? true : false));
const showedValue = computed({
	get: () => {
		if (props.select == true && props.trackBy && !props.selectMultiple) return props.options.find((option) => option[props.trackBy] === valueComputed.value);
		if (props.selectMultiple == true && props.trackBy != undefined) return valueComputed.value.map((val) => props.options.find((option) => option[props.trackBy] == val));
		if (props.isPrice) {
			// return helper.currencyFormat(valueComputed.value);
		}
		return (props.multiLanguage ? valueComputed.value[showed_language.value] : valueComputed.value) ?? '';
	},
	set: (val) => {
		validationStore.resetValidationByNameAction(props.name);
		if (val == null) return;
		let checkedValue = val;
		let currentValue = valueComputed.value;
		if (props.select && props.trackBy != undefined) checkedValue = val[props.trackBy];
		if (props.selectMultiple && props.trackBy != undefined) checkedValue = val.map((val) => val[props.trackBy]);
		if (props.isPrice) {
			checkedValue = prepareInput(val);
			// checkedValue = helper.currencyFormat(checkedValue);
		}
		props.multiLanguage ? (currentValue[showed_language.value] = checkedValue) : (currentValue = checkedValue);
		emit('input', currentValue);
		if (props.isPrice) {
			emit('update:value', prepareInput(currentValue));
		} else {
			emit('update:value', currentValue);
		}
		props.input(currentValue);
	},
});



const prepareInput = (val) => {
	return typeof val === 'string' ? val.replace(/,/g, '').replace(/\./g, '') : val;
};
let keyPressAction = (e) => {
	const currentValue = e.target.value;
	const caretPosition = e.target.selectionStart;
	if (props.isPrice) {	
        const numberWithDots = currentValue.toString()
	    let  newValue= numberWithDots.replace(/\./g, "");
		if (newValue.length >= 1 && newValue.startsWith('0')) {
			e.target.value = newValue.replace(/^0+/, '');
			newValue=e.target.value;
		}
		emit('input', newValue);
	} else {
		keyPressAction = (event) => emit('keyPress', event);
	};
};


const selectedLabel = ref(valueComputed.value.name)
const selectedItem = ref(null)
const filteredOptions = ref([...props.options]); 

watch(() => props.options, (newOptions) => {
    filteredOptions.value = [...newOptions];
});

const filterOptions = ($event) => {
	const searchQuery = $event.target.value.toLowerCase();
	
	selectedLabel.value = $event.target.value; 
	if (!searchQuery) {
		filteredOptions.value = props.options
		return
	}
	if (!isSelectMenuOpen.value) {
		isSelectMenuOpen.value = true
	} 
	filteredOptions.value = props.options.filter(option =>
		option[props.label]?.toLowerCase().includes(searchQuery)
	);
}

const updateSelectedItem = (event, item) => {
	showedValue.value = item[props.label || 'name'];
	const selected = props.options.find((option) => option[props.trackBy] == item[props.trackBy])

	selectedLabel.value = selected ? selected[props.label || 'name'] : ''
	selectedItem.value = selected || {}
	emit('update:value', selected[props.trackBy]); // Return Just Track By Key
	emit('update:item', selected); // Return Whole Item
}

const changeShowedLanguage = (locale) => {
	if (props.editor == true) inputEditor.value.setContents(valueComputed.value?.[locale] ?? '');
	valueComputed.value[locale] = valueComputed.value[locale] ?? '';
	showed_language.value = locale;
	showedLanguageBar.value = false;
};
const selectElement=ref(null)
const closeOnOutsideClick = (event) => {
if (selectElement.value && !selectElement.value.contains(event.target)) {
	isSelectMenuOpen.value = false;
}
};

// Add event listener on mount and clean up on unmount
onMounted(() => {
document.addEventListener('click', closeOnOutsideClick);
});
</script>
<template>
	<div
	    ref="selectElement"
		v-if="select"
		class="bg-white relative block w-full ring-1 ring-gray-300 ring-inset border shadow-sm text-gray-900 text-base h-10 rounded-md focus:ring-2 focus:ring-inset focus:ring-indigo-600 max-w-full sm:text-sm sm:leading-6"
		:id="nameComputed + '_id'"
		@click.outside="() => isSelectMenuOpen = !isSelectMenuOpen"
	>
		<input 
			class="w-full h-10 p-3 bg-transparent focus:outline-none truncate to-blue-900"
			type="text" 
			:placeholder="placeholder" 
			:value="selectedLabel" 
			@input="filterOptions"
			:disabled="disabled"
		/>
		<button id="headlessui-combobox-button-v-0-0-0-0-3-0-1" :disabled="disabled" type="button" tabindex="-1" aria-haspopup="listbox" aria-expanded="false" data-headlessui-state="" class="absolute inset-y-0 end-0 flex items-center px-2.5">
			<i class="fa-solid fa-chevron-down"></i>
		</button>
		<div v-if="isSelectMenuOpen" class="bg-white p-2 border rounded-md relative z-[9999] w-full h-64 max-h-fit overflow-auto">	
			<div 
				class="flex gap-2 hover:bg-gray-300 " 
				v-for="(option, i) in filteredOptions"
				:key="i"
				@click="updateSelectedItem($event, option)"
			>
				<span :class="`fi fi-${option.code}`"></span>
				<p class="truncate">{{ option.name }}</p>
			</div>
		</div>
	</div>

	<textarea
		v-else-if="type === 'textarea'"
		:name="nameComputed"
		:id="nameComputed + '_id'"
		v-model="showedValue"
		:rows="10"
		:placeholder="placeholder"
		:disabled="disabled"
		@keypress="keyPressAction"
		class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm bg-white h-40 text-gray-900 text-sm p-3 rounded-md my-2 focus:outline-none"
	></textarea>

	<input
		v-else
		:type="type"
		:placeholder="placeholder"
		v-model="showedValue"
		class="block w-full ring-1 ring-gray-300 ring-inset shadow-sm h-10 text-gray-900 text-sm p-3 rounded-md"
		:name="nameComputed"
		:id="nameComputed + '_id'"
		@input="keyPressAction"
		:disabled="disabled"
	/>	
	<div v-if="validationError[nameComputed]" class="text-red-700">
		* {{validationError[nameComputed][0]}}
	</div>
</template>